<template>
    <div class="modal fade bonus-point-modal balance-low show" id="LowBalanceModal" aria-modal="true" role="dialog">
         <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header border-0">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body p-body">
                  <div class="flex-box-item">
                     <img src="@/assets/images/wallet-deposit.webp" alt="" class="wallet-deposit">
                     <p class="check-blow-item">Looks like your balance is too low</p>
                     <p class="deposit-balnce">Deposit balance to continue playing.</p>
                  </div>
               </div>
               <div class="modal-footer border-0">
                  <button type="button" class="btn btn-gradient" @click="goToDeposit()" data-bs-dismiss="modal">Deposit Balance</button>
               </div>
            </div>
         </div>
      </div>
</template>

<script>
export default {
    name: 'LowBalanceRemainder',

    methods: {
        goToDeposit() {
            this.$router.push({name: 'wallet-gateway'});
        },

    },
}
</script>
<template>
    <div class="modal-dialog rules-modal" :class="{'d-none' : !siteRuleData}">
        <div class="modal-content">
            <div class="modal-header">
                <div class="thm-heading rule-heading">
                    <h2 class="text-dark">Rules & Results</h2>
                </div>
                <button type="button" class="btn-close rule-cls-btn" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body rule-height" ref="modalBody" @scroll="handleScroll">
                <div class="back-modal">
                    <div class="language-list d-block" v-html="siteRuleData?.['General']"></div>
                </div>
                <div v-if="showGoToTopButton" class="back-to-top thm-but rules-go-to-top" @click="goToTop()">
                    <img src="@/assets/images/arow-up-white.webp" alt="">
                </div>
            </div>
            <div class="back-modal-footer ">
                <button type="button" class="btn btn-rules place-but w-100" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RulesModal',
    inject:['translatedLangData'],
    props: ['siteRuleData'],
    data(){
        return{
            showGoToTopButton: false
        }
    },
    watch: {
        siteRuleData() {
            this.$nextTick(this.handleScroll);
        }
    },
    methods: {
        goToTop() {
            const modalBody = this.$refs.modalBody;
            if (modalBody) {
                modalBody.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
        handleScroll() {
            const modalBody = this.$refs.modalBody;
            if (modalBody.scrollTop > 200) {
                this.showGoToTopButton = true;
            } else {
                this.showGoToTopButton = false;
            }
        }
    }
}
</script>

<style scoped>
    .rule-height {
        overflow-y: auto;
        height: calc(100vh - 110px);
    }
    .rules-go-to-top {
        position: fixed;
        z-index: 99;
        right: 12px;
        bottom: 63px;
    }
    .modal-body {
        position: relative;
    }
    @media screen and (max-height: 424px){
        .rules-modal{
            position:  relative !important;
        }
    }
    .rule-heading{
        width: 100% !important;
        background: var(--dark-theme-card-bg-light-gradiant) !important;
    }
    .rule-heading h2{
        color: white !important;
    }
    .rule-cls-btn{
        top: 24px !important;
    }
    .btn-rules{
        background: var(--bs-secondary) !important;
    }
</style>
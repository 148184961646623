const FavouriteModule = () => import(/* webpackChunkName: "favourite-module" */ './views/Module.vue')
const Favourites = () => import(/* webpackChunkName: "favourites" */ './views/Favourites.vue');

const FavouriteRoutes = {
    path: '/',
    component: FavouriteModule,
    children: [
        {
            path: 'favourites',
            name: 'favourites',
            component: Favourites
        }
    ],
}

export default FavouriteRoutes;
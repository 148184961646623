<template>
    <div class="container">
        <div class="flex-box" :class="!checkIsLogin() ? '':''">
            <div class="header-logo">
                <a v-if="checkForBackArrow()" @click="$router.back()">
                    <div class="back-home">
                        <div class="back-arrow-img">
                           <img src="@/assets/images/header-back-arrow.webp" alt="">
                        </div>
                        <p>{{getTitle()}}</p>
                     </div>
                </a>
                <router-link to="/" v-else>
                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo" class="web-logo" />
                </router-link>
                <!--  -->
            </div>
            <div class="login" v-if="!checkIsLogin()">
                <div class="btn-design-outer">
                    <template v-if="excludedRoutes.includes($route.name)">
                        <router-link to="/">
                        <span>
                            <img src="@/assets/images/close.webp" alt="" class="close-btn" />
                        </span>
                    </router-link>
                    </template>
                    <template v-else>
                    <router-link to="/login" class="login-btn">
                        <span>{{translatedLangData('login','Login')}}</span>
                    </router-link>
                    <router-link to="/sign-up" class="login-btn change-bg" v-if="siteSettings && siteSettings?.business_type == 2">
                        <span>{{translatedLangData('sign-up','sign up')}}</span>
                    </router-link>
                    </template>
                </div>
            </div>
            <div class="massage-wrapper" v-else>
                  <ul>
                    <li v-if="$route.name=='sports-event-detail'">
                        <div class="btn-design-outer">
                            <button class="live-score" type="button" data-bs-toggle="collapse" @click="openTV()"
                            data-bs-target="#collapseExampleed" aria-expanded="false" aria-controls="collapseExample">
                            <div class="live-tv">
                                <img src="@/assets/images/tv_white_new.webp" alt="" />
                            </div>
                            </button>
                        </div>
                    </li>
                     <li>
                        <div class="btn-design-outer">
                           <router-link to="/messages" class="ball-btn">
                              <div class="notifiction">
                                 <img src="@/assets/images/ball.webp" alt="" />
                              </div>
                              <div class="note">
                                 {{unreadNotificationCount}}
                              </div>
                           </router-link>
                        </div>
                     </li>
                     <li v-if="siteSettings?.business_type == 2">
                        <div class="btn-design-outer">
                           <a @click="getWalletGateway()" class="wall-btn">
                              <div class="wal-green">
                                 <!-- <img src="@/assets/images/deposit-newicon.webp" alt="" /> -->
                                  <span>deposit</span>
                              </div>
                           </a>
                        </div>
                     </li>
                     <a @click="toggleProfile()">
                        <li>
                            <div class="btn-design-outer">
                                <div class="user-id">
                                    <div class="user">
                                        <p>{{userData?.userid}}</p>
                                        <p class="balance">{{translatedLangData('bal','Bal')}}: <span class="font-green">{{userData?.balance}}</span></p>
                                    </div>
                                    <div class="down-arrow" :class="$route.name == 'profile' ? 'rotate-arrow' :''">
                                        <img src="@/assets/images/chevron-down-light.webp" alt="" />
                                    </div>
                                </div>
                            </div>
                        </li>
                     </a>
                  </ul>
               </div>
        </div>
         <!-- <div class="moving-text-sec" v-if="siteSettings?.announcement && $route.name == 'Home'">
            <marquee behavior="scroll" direction="" scrollamount="10" onmouseover="this.stop();" onmouseout="this.start();">
            {{ siteSettings.announcement }}
            </marquee>
        </div> -->
    </div>
</template>

<script>
import oddApi from '@/shared/services/click_api';
import * as apiName from '@/shared/services/urls';
import { mapGetters } from 'vuex';

export default {
name: "Header",
inject:['translatedLangData'],
    data(){
        return{
            excludedRoutes:[
                'login',
                'sign-up',
                'forget-password'
            ],
            non_custom_sports:[],
            custom_sports:[],
            userData:null,
            unreadNotificationCount: null,
            cricketFight_eventName: JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : '',
            myContest_eventName: JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '',
            lock_settings:null,
            IsopenTV: false,
        }
    },
    computed: {
         ...mapGetters({
            siteSettings: 'siteSettings' , stateUser : 'stateUser'
        })
      },
     mounted(){
        this.userData=this.$store?.getters?.stateUser;
        this.lock_settings = this.$store?.getters?.lock_settings;
        this.unreadNotificationCount = localStorage.getItem('unread_notification_count') ? localStorage.getItem('unread_notification_count') : 0;
        this.getSportsList()
    },
    methods:{
        openTV(){
            this.IsopenTV = !this.IsopenTV;
            this.$store.dispatch('setShowTV', this.IsopenTV);
        },
        toggleProfile(){
            this.$route.name == 'profile' ? this.$router.go(-1) : this.$router.push('/profile')
        },
        checkIsLogin() {
        return this.$store?.getters?.isAuthenticated;
        },
         checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.lock_settings?.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
         getSportsList(){
            this.loading = true;
            oddApi.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports=[]
                        for(var i=0;i<all_sports.length;i++){
                            if(this.checkEventManageforSports(all_sports[i].id)) {
                                if(all_sports[i].is_custom==0){
                                    this.non_custom_sports.push(all_sports[i])
                                }else{
                                    this.custom_sports.push(all_sports[i])
                                }
                                all_sports[i]?.name?.toLowerCase() === 'soccer' ? all_sports[i].name = 'Football' : '';
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list={}
                        sport_list['custom']=this.custom_sports
                        sport_list['non_custom']=this.non_custom_sports
                        // localStorage.setItem('sports_List',JSON.stringify(sport_list))
                        this.$store.dispatch('setSportsData', sport_list);
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    // this.showErrorModalFunc(error.data.message);
                }
            });
        },
        checkForBackArrow() {
            let routeName = this.$route.name;
            // let routeType = this.$route.params.type;
            switch (routeName) {
                case 'racing-category':
                  return true;
                case 'CricketFight':
                  return true;
                case 'MatchDetails':
                  return true;
                case 'PlaceBet':
                  return true;
                case 'MyContests':
                  return true;
                case 'MyContestBetDetails':
                  return true;
                default:
                  return false
            }
         },
        getTitle() {
            let routeName = this.$route.name;
            if (routeName == 'racing-category') {
               let routeType = this.$route.params.type;
               switch (routeType) {
                  case 'HORSE_RACE':
                     return 'Horse Race'
                  case 'GREY_HOUND_RACE':
                     return 'Greyhound Race'
                  default:
                     return 'Sports';
               }
            } else if (routeName == 'CricketFight') {
               return 'CRICKET FIGHT'
            }
            else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
               return JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : ''
            }
            else if (routeName == 'MyContests') {
               return 'My Contests'
            }
            else if (routeName == 'MyContestBetDetails') {
               return JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '';
            }
        },
        getWalletGateway() {
            this.$router.push({name: 'wallet-gateway'});
        }
    }
}
</script>

<style scoped>
.user p{
    font-weight: 400;
}
.rotate-arrow{
    transform: rotate(180deg) !important;
}
</style>
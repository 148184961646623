import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const AboutUs = () => import("@/shared/components/terms-policy/AboutUs.vue");
const AMLPolicy = () => import("@/shared/components/terms-policy/AMLPolicy.vue");
const Faq = () => import("@/shared/components/terms-policy/Faq.vue");
const KYCPolicy = () => import("@/shared/components/terms-policy/KYCPolicy.vue");
const PrivacyPolicy = () => import("@/shared/components/terms-policy/PrivacyPolicy.vue");
const ResponsibleGaming = () => import("@/shared/components/terms-policy/ResponsibleGaming.vue");
const SelfExclusionPolicy = () => import("@/shared/components/terms-policy/SelfExclusionPolicy.vue");
const TermsAndConditions = () => import("@/shared/components/terms-policy/TermsAndConditions.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");
import AuthorizationRoutes from '@/modules/authorization';
import FavouriteRoutes from "@/modules/favourites";
import MatkaRoutes from "@/modules/matka";
import CricketFightRoutes from "@/modules/cricket-fight";
import StatementRoutes from "@/modules/statements";
// import MyMarketRoutes from "@/modules/my-market";
import ReferEarnRoutes from "@/modules/refer-earn";
import BonusRoutes from "@/modules/bonus";
import RaceRoutes from "@/modules/race";
import GamesRoutes from "@/modules/games";
import WalletRoutes from "@/modules/wallet";
import SportsRoutes from "@/modules/sports";
import ProfileRoutes from "@/modules/profile";
import SportsBookRoutes from "@/modules/sports-book";

const routes = [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/about-us",
      name: "about-us",
      component: AboutUs
  },
  {
      path: "/aml-policy",
      name: "aml-policy",
      component: AMLPolicy
  },
  {
      path: "/faq",
      name: "faq",
      component: Faq
  },
  {
      path: "/kyc-policy",
      name: "kyc-policy",
      component: KYCPolicy
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy
  },
  {
    path: "/responsible-gaming",
    name: "responsible-gaming",
    component: ResponsibleGaming
  },
  {
    path: "/self-exculusion-policy",
    name: "self-exculusion-policy",
    component: SelfExclusionPolicy
  },
  {
      path: "/terms-conditions",
      name: "terms-conditions",
      component: TermsAndConditions
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceComponent
  },

 
 
    AuthorizationRoutes,
    FavouriteRoutes,
    MatkaRoutes,
    CricketFightRoutes,
    StatementRoutes,
    // MyMarketRoutes,
    ReferEarnRoutes,
    BonusRoutes,
    RaceRoutes,
    GamesRoutes,
    WalletRoutes,
    SportsRoutes,
    ProfileRoutes,
    SportsBookRoutes
  ];
  
  
  export default createRouter({
    history: createWebHistory(),
    routes
  });
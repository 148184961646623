const SportBookModule = () => import(/* webpackChunkName: "sport-book-module" */ './views/Module.vue');
const SportsBook = () => import(/* webpackChunkName: "sport-book" */ './views/SportsBook.vue');

const SportBookRoutes = {
    path: '/',
    component: SportBookModule,
    children: [
        {
            path: 'sports-book',
            name: 'sports-book',
            component: SportsBook
        }
    ],
}

export default SportBookRoutes;
<template>
    <!-- header section -->
	<template v-if="!underMaintenance">
	<header v-if="checkForHeaderShow()" :class="excludedRoutes.includes($route.name) ? 'login-trans': 'heading-section'" loading="lazy">
		<Header>
		</Header>
	</header>
	</template>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>
    <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @updateAuthToken="refreshAuthToken" v-if="siteSettings || underMaintenance" />

      <!-- header section -->
	   
		<template v-if="!underMaintenance">
			<Footer v-if="!footerExcludedRoutes.includes($route.name)" ref="footer" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ></Footer>
		</template>
    <!-- </footer> -->
	<aside class="my-bets-footer">
        <div class="offcanvas offcanvas-end sidemenus-offcanvas" data-bs-scroll="true" tabindex="-1" id="mybetSideMenu" aria-labelledby="mybetSideMenu" data-bs-backdrop='static' data-bs-keyboard="false">
            <div class="offcanvas-menu">
				<MyBetSideMenu v-if="checkIsLogin() && mybetsTabActive" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
			</div>
		</div>
	</aside>
	<a @click="getParentWhatsappNumber" v-if="siteSettings && siteSettings?.business_type == 2 && checkIsLogin() && routePath == 'Home'" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal">
		<div class="whatsapp-icon-btn">
			<img class="w-img" src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp">
		</div>
	</a>
	<!-- <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent> -->
	<GotoTop/>

     <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>

  	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<Offers />
	<LowBalanceRemainder @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<WhatsAppModal :whatsappNumbers="whatsappNumbers" />
	<LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"/>
<div ref="click_logout_msg" @click="showErrorModalFunc('You are logout from this device.')"
	style="display: hidden;">
</div>
</template>

<script>
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import LanguageSelection from '@/shared/components/header/LanguageSelection.vue';
import MyBetSideMenu from '@/shared/components/header/MyBetSideMenu.vue'
import Header from '@/shared/components/header/Header.vue'
import Footer from '@/shared/components/footer/Footer.vue'
import SignUp from '@/modules/authorization/views/SignUp.vue'
import WhatsAppModal from '@/shared/components/modal/whatsAppModal.vue';
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import GamesPop1 from './shared/components/modal/GamesPop1.vue';
import GamesPop2 from './shared/components/modal/GamesPop2.vue';
import LowBalanceRemainder from '@/shared/components/modal/LowBalanceRemainder.vue'
// import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
import GotoTop from '@/shared/components/footer/GotoTop.vue';
import Offers from "./shared/components/header/Offers.vue";

import moment from 'moment';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import api from '@/shared/services/api';
import sportCount_api from '@/shared/services/sportcount-api';
import * as apiName from '@/shared/services/urls';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { callFavouriteApis } from '@/shared/services/common-services';
import socketService from "./socket/sport-socket-service";
import captchaApi from "./modules/authorization/services/captcha-api";
import { provide ,computed } from 'vue'
import LanguageService from '@/shared/services/language-service.js';
import { Modal } from 'bootstrap';
import Maintenance from './shared/components/maintenance/Maintainence.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SignUp,
    SuccessModal,
    ErrorModal,
	GamesPop1,
	GamesPop2,
	MyBetSideMenu,
	LowBalanceRemainder,
	// ChatComponent,
	GotoTop,
	Offers,
	WhatsAppModal,
	LanguageSelection,
	NotificationAlert,
	Maintenance

  },
  provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
  data(){
        return{
            excludedRoutes:[
                'login',
                'sign-up',
                'forget-password',
            ],
			footerExcludedRoutes:[
				'login',
                'sign-up',
                'forget-password',
				'MatchDetails',
				'privacy-policy',
				'responsible-gaming',
				'terms-conditions',
				'about-us',
				'kyc-policy',
				'aml-policy',
				'self-exculusion-policy'

			],
            onLine: navigator.onLine,
            showBackOnline: false,
            showSuccessModal: false,
            successMsg: "",
            showErrorModal: false,
            errorMsg: "",
            refreshInit: false,
            scY: 0,
            scTimer: 0,
			LowBalanceModal:null,
			whatsappNumbers:[],
			langData: null,
			findActiveLang:"",
			allLanguages:null,
			offerModal: '',
			versionData:null,
			UpdatedVersion:null,
        }
    },
    computed: {
		...mapGetters({
			mybetsTabActive: 'mybetsTabActive',siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name
		},
		siteVersion() {
			return this.$store.getters.siteVersion;
		},
		underMaintenance() {
			return this.$store.getters.underMaintenance;
		},
		
	},
	async created() {
		await this.getSiteVersion();
			this.getAllSportsCount();
			this.getBonusSettings();
		},
    mounted() {
      //for use on js pages
      window.store = this.$store;
      window.cookies = this.$cookies;
      window.router = this.$router;
	  this.offerModal = new Modal("#offer_pop_up");
      window.emitLogoutMsg = this.$refs.click_logout_msg
      window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
      //for add on all APIs
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
      setHeaders();
      if (this.checkIsLogin()) {
        callFavouriteApis();
		if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
		}
		if(this.$store.getters.stateUser.notificationSubscribed!=1) {
			this.requestNotificationPermission()
		}
      }

      //checking internet connection
      window.addEventListener('online', this.updateOnlineStatus);
      window.addEventListener('offline', this.updateOnlineStatus);
      if (localStorage.getItem('default_class')) {
        document.documentElement.className = localStorage.getItem('default_class')
      }
      window.addEventListener('scroll', this.onScroll);
	  this.socketAllEvents()
	  this.$store.dispatch('setMybetsTabActive', false);

	  if (this.checkIsLogin() && this.$route.name=='sports-event-detail'){
			this.userData = this.$store?.getters?.stateUser;
			var balance = this.userData.balance
			this.LowBalanceModal = new Modal(document.getElementById('LowBalanceModal'));
			if(balance < 100){
				setInterval(() => {
					this.LowBalanceModal.show();
				}, 120000)
			}
		}
    },
    methods: {
		getBonusSettings() {
            api.get(apiName.GET_BONUS_SETTINGS + "?domain=" + window.location.hostname).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let bonusData = response.data.data;
                            this.$store.dispatch('setBonusData', bonusData);
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
		translatedData(key, defaultVal) {
			if(this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
		async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();
                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			await api.get(apiName.getVersionStatus + "?domain=" + window.location.hostname).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						this.UpdatedVersion =  resData?.version;
						this.versionData = resData;
						const storedVersion = this.siteVersion;
						if (!this.UpdatedVersion) {
           				   this.UpdatedVersion = '1'; 
						}
						if (!storedVersion || String(storedVersion) !== String(this.UpdatedVersion) || !this.siteSettings) {
							await this.getSiteSettingsServiceCall();
						}
						else {
							this.siteSettingCommon(this.siteSettings);
							}
						const fromDateTime = new Date(resData?.maintenance?.from_date).getTime();
						const toDateTime = new Date(resData?.maintenance?.to_date).getTime();
						const currentDateTime = Date.now(); 
						if (resData.maintenance != null && (fromDateTime && toDateTime && fromDateTime <= currentDateTime && currentDateTime <= toDateTime)) {
							this.$store.dispatch('setUnderMaintenance', resData.maintenance);
							this.$store.dispatch('setGlobalLoader', false);
							this.$router.push({name:'Maintenance'});
							return;
						} else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
						this.$store.dispatch('setUnderMaintenance', null); 
						this.$store.dispatch('setGlobalLoader', false);
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		async getSiteSettingsServiceCall() {
			await api.get(apiName.v1_SITE_SETTINGS + "?domain=" + window.location.hostname).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data.data.site_settings
						let casino = response.data.data?.casino
						this.$store.dispatch('setSettings', resData);
						this.$store.dispatch('setGamesData', casino);
						this.siteSettingCommon(resData);
						this.$store.dispatch('setLanguagesData', this.allLanguages);
					}
				}
			}).catch(error => {
				console.log("Site setting Error ",error)
				this.$store.dispatch('setGlobalLoader', false);
				this.$store.dispatch('setSiteVersion', null);
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		async siteSettingCommon(siteSetting) {
			try {
				this.allLanguages = siteSetting.language_translate_list;
				if (this.allLanguages) {
				this.findActiveLang = this.allLanguages.find(lang => lang.lang_code === siteSetting.language_code);
				const selectedLanguageUrl = localStorage.getItem('selectedLanguageUrl');
				if (!(selectedLanguageUrl === 'en' || (!selectedLanguageUrl && siteSetting.language_code === 'en'))) {
					if ((this.findActiveLang && this.findActiveLang.lang_url) || selectedLanguageUrl) {
					const langCode = selectedLanguageUrl || this.findActiveLang.lang_url;
					const aws_url_language = siteSetting.aws_url + '/' + langCode;
					this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
					}
				}
				}
				this.setManifest(siteSetting);
				// Create FAVICON
				const favElement = document.createElement('link');
				favElement.rel = 'icon';
				favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
				document.head.insertAdjacentElement('beforeend', favElement);

				const title = document.getElementById("title");
				title.text = siteSetting?.domain_name;

				let is_offer = localStorage.getItem("is_offer");
				if (siteSetting.front_css != null && siteSetting.front_css != '') {
				const styleElement = document.createElement('style');
				styleElement.type = 'text/css';
				styleElement.appendChild(document.createTextNode(siteSetting.front_css));
				document.head.insertAdjacentElement('beforeend', styleElement);
				}

				if (this.checkIsLogin()) {
				this.getWalletBalance();
				}

				if (this.checkIsLogin() && siteSetting.offer != null && is_offer == null) {
				this.offerModal.show();
				}
				this.$store.dispatch('setSiteVersion', this.UpdatedVersion);
			} catch (error) {
				this.$store.dispatch('setGlobalLoader', false);
				this.$store.dispatch('setSiteVersion', null);
				this.showErrorModalFunc(error);
			}
    },
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				console.log("wallet balance Error ")
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.refreshInit = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				setTimeout(function () {
					this.getWalletBalance();
				}.bind(this), 2000);
				if(refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		getAllSportsCount(){
			this.loading = true;
            sportCount_api.get(apiName.SPORTS_COUNT).then(response => {
                this.loading = false;
                if (response && response.status == 200) {
					let resData =response?.data.data
					this.$store.dispatch('SetSportsCount',resData)
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                }
            });
		},
		checkForHeaderShow() {
			if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents(){
			if(this.$store.getters.stateUser){
				socketService.emit("sub",'depositRequest-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'logout-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'banned-'+this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type =="myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});	
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					}else if(data.type == 'league_ban'){
						// league ban condition
					}
				});
					
			}			
		}
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
 .pr-2{
	padding-right: 5px !important;
 }
</style>

const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const LiveCasino = () => import(/* webpackChunkName: "games" */ './views/LiveCasino.vue'); 
const PopularGames = () => import(/* webpackChunkName: "games" */ './views/PopularGames.vue'); 
const SlotGames = () => import(/* webpackChunkName: "games" */ './views/SlotGames.vue'); 
const InstantGames = () => import(/* webpackChunkName: "games" */ './views/InstantGames.vue'); 
const RoyalGames = () => import(/* webpackChunkName: "games" */ './views/RoyalGames'); 

const GamesRoutes = {
    path: '/games',
    component: GamesModule,
    children: [
        {
            path: 'live-casino',
            name: 'live-casino',
            component: LiveCasino
        },
        {
            path: 'popular-games',
            name: 'popular-games',
            component: PopularGames
        },
        {
            path: 'slot-games',
            name: 'slot-games',
            component: SlotGames
        },
        {
            path: 'instant-games',
            name: 'instant-games',
            component: InstantGames
        },
        {
            path: 'royal-games',
            name: 'RoyalGames',
            component: RoyalGames
        }
    ],
}

export default GamesRoutes;
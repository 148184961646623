import axios from 'axios'
import { headers } from '@/shared/services/headers'
import { TIME_5000 } from '@/shared/constants/services-const'

const instance = axios.create({
  baseURL: process.env.VUE_APP_CLICK_API,
  timeout: TIME_5000,
  headers: headers
})

instance.interceptors.response.use(function (response) {
  return Promise.resolve(response);
}, function (error) {
  if (error) {
    console.log("Error in interceptor : ", error.response);
    if (error?.response?.status == 422) {
      return Promise.reject(error.response.data.details[Object.keys(error.response.data.details)[0]]);
    }
    else if(error?.response?.status == 401 )
    {
      
    }
    else {
      return Promise.reject(error?.response);
    }
  }

});

export default instance;
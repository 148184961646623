
export const SITE_SETTINGS='api/site-settings'
export const GET_SPORTS= 'v1/sports/management/getSport';
export const LOGOUT_API='api/logout';
export const AUTH_STATUS='api/auth-status';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_FAVOURITE_LIST='api/favorite-list';

export const USER_PROFILE='api/user-profile'
export const AUTH_API= '/api/auth-status'
export const WALLET_BALANCE='wallet';
export const AUTH_REFRESH='api/auth-refresh';
export const CAPTCHA_API='api/v1/captcha'
export const MATCHES_BY_SPORT_ID = 'v1/events/matches/';
export const SPORTS_COUNT='api/v1/events/count';
export const GET_MY_BETS='api/lotus/get-my-bets';
export const  OPEN_BETS_EVENT_LISTING='api/open-bets-event-listing';
export const SEARCH='api/search';
export const GET_LOCKED_EARNED_BONUS = 'api/get-locked-earn-bonus';
export const UPDATE_WALLET_TYPE = 'api/wallet-switch';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';
export const GET_BONUS_SETTINGS = 'api/get-bonus-settings';
export const getVersionStatus= 'api/site-status-version';
export const v1_SITE_SETTINGS= 'api/v1-site-settings';
export const GET_SITE_RULES='api/get-site-rules';
<template>
     <aside class="menu-footer">
         <div class="offcanvas offcanvas-start sidemenus-offcanvas" tabindex="-1" id="sideMenu" aria-labelledby="sideMenuLabel">
            <div class="offcanvas-menu">
               <div class="menu-offcanvas-header">
                  <div class="search">
                     <input type="search" ref="searchBarRef" :placeholder="translatedLangData('search-events','Search events')" v-model="searchText" @keyup="searchIcon" />
                     <div class="search-img" >
                        <img src="@/assets/images/magnifier-black.webp" alt="" />
                     </div>
                  </div>
               </div>
               <!-- --------------------------------- -->
               <ul class="search-result-ul" v-if="searchResult && searchResult.length > 0">
                  <li v-for="(item,index) in searchResult" :key="index" data-bs-dismiss="offcanvas">
                     <a @click="clickOnSportItem(item), $emit('toggleBackButton')">{{ item?.event_name }}</a>
                  </li>
               </ul>
               <ul class="search-result-ul" v-if="searchText != '' && searchResult && searchResult.length == 0">
                  <li>
                     <a>{{translatedLangData('no-rec-found','No Events Found \'') + searchText + "\'"}}.</a>
                  </li>
               </ul>
               <div class="menu-offcanvas-body">
                  <div class="bouns-sec">
                     <div class="bonus-now">
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                           <router-link to="/favourites" @click="$emit('toggleBackButton')">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/star-sidebar1.svg" alt="" />
                                 </div>
                                 {{translatedLangData('favourite','Favorites')}}
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                        <div class="bonus-cum-list" data-bs-dismiss="offcanvas" v-if="siteSettings && siteSettings?.business_type == 2 && bonusAvailable">
                           <router-link to="/bonus" @click="$emit('toggleBackButton')">
                              <div class="bonus-icon">
                                 <div class="bonus-img">
                                    <img src="@/assets/images/bonus-sidebar-blue-new1.webp" alt="" />
                                 </div>
                                 {{translatedLangData('bonus','Bonus')}}
                              </div>
                              <div class="bonus-btn">
                                 <div class="arrow-btn">
                                    <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                 </div>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="Others sports-game">
                        <div class="collapse-btn">
                           <h2 class="sports-name">{{translatedLangData('sports','Sports')}}</h2>
                           
                           <div class="accordion accordion-flush" id="accordionFlush">
                              <div class="accordion-item collapse-bar" v-for="(sport, n_index) in primary_non_custom_sports" :key="n_index">
                                 <h2 class="accordion-header sport-acc-div" :id="'flush-headingOnes' + n_index">
                                    <div class="bonus-cum-list flex-d pt-0 pb-0 sport-acc" data-bs-dismiss="offcanvas"
                                       @click="gotoSportPage(sport), $emit('toggleBackButton')">
                                    <div class="bonus-icon">
                                       <div class="bonus-img">
                                          <img :src="getSportImage(sport.id)" alt="" loading="lazy" />
                                       </div>
                                       {{ translatedLangData(sport.slug, capitalizeFirstLetter(sport.name)) }}
                                    </div>
                                    </div>
                                    <button 
                                    class="accordion-button flex-btn collapsed sport-acc-btn" 
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    :data-bs-target="'#flush-collapsethreeon' + n_index" 
                                    aria-expanded="false" 
                                    :aria-controls="'flush-collapsethreeon' + n_index" 
                                    @click="getLeagues(sport)">
                                    <div class="accordion-div">
                                       <div class="flex-box w-cusd">
                                          <div class="content-btn">{{sportsCount?.[sport.id]?.total || 0}}</div>
                                          <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                       </div>
                                    </div>
                                    </button>
                                 </h2>
                                 <div 
                                    :id="'flush-collapsethreeon' + n_index" 
                                    class="accordion-collapse collapse" 
                                    :aria-labelledby="'flush-headingOnes' + n_index" 
                                    data-bs-parent="#accordionFlush">
                                    <div class="accordion-body collapse-bar">
                                    <div class="accordion accordion-flush" :id="'nestedAccordion' + n_index" v-if="sport.leagueList && Object.keys(sport.leagueList)?.length > 0">
                                       <div class="accordion-item" v-for="(league, l_index,lnIndex) in sport.leagueList" :key="l_index">
                                          <h2 class="accordion-header" :id="'nestedHeading' + n_index + '-' + l_index">
                                          <button 
                                             class="accordion-button flex-btn collapsed" 
                                             type="button" 
                                             data-bs-toggle="collapse" 
                                             :data-bs-target="'#nestedCollapse' + n_index + '-' + lnIndex" 
                                             aria-expanded="false" 
                                             :aria-controls="'nestedCollapse' + n_index + '-' + lnIndex">
                                             <div class="accordion-div">
                                                <div class="bonus-cum-list flex-d">
                                                <div class="bonus-icon ellipese-word">
                                                   <div class="bonus-img">
                                                      <img src="@/assets/images/trophy-sidebar-red.webp" alt="" />
                                                   </div>
                                                   {{ l_index }}
                                                </div>
                                                </div>
                                             </div>
                                             <div class="flex-box w-cusd">
                                                <div class="content-btn">{{ league?.length }}</div>
                                                <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                             </div>
                                          </button>
                                          </h2>
                                          <div 
                                          :id="'nestedCollapse' + n_index + '-' + lnIndex" 
                                          class="accordion-collapse collapse" 
                                          :aria-labelledby="'nestedHeading' + n_index + '-' + l_index" 
                                          :data-bs-parent="'#nestedAccordion' + n_index">
                                          <div class="accordion-body">
                                             <div class="show-and-hide hidden-menu">
                                                <div class="bonus-cum-list" v-for="(match, m_index) in league" :key="m_index">
                                                <a @click="clickOnSportItem(match), $emit('toggleBackButton')" data-bs-dismiss="offcanvas">
                                                   <div class="bonus-icon ellipese-word">
                                                      <div class="bonus-img dry-cic">
                                                      <img v-if="match?.inplay" src="@/assets/images/right-caret.svg" alt="" />
                                                      <img v-else src="@/assets/images/dry-clean.webp" alt="" />
                                                      </div>
                                                      {{ match.event_name }}
                                                   </div>
                                                   <div class="bonus-btn">
                                                      <div class="arrow-btn">
                                                      <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                      </div>
                                                   </div>
                                                </a>
                                                </div>
                                             </div>
                                          </div>
                                          </div>
                                       </div>
                                    </div>
                                    <!-- Optionally, add your "No markets" message here if needed -->
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="bonus-now" data-bs-dismiss="offcanvas">
                                 <div class="bonus-now" v-if="checkEventManageforSingleType('worli-matka')">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/matka" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/menu-2378961.webp" alt="" />
                                             </div>
                                             {{translatedLangData('matka','Matka')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                                    <div class="bonus-now" data-bs-dismiss="offcanvas">
                                       <div class="bonus-cum-list">
                                          <router-link to="/cricket-fight" @click="$emit('toggleBackButton')">
                                             <div class="bonus-icon">
                                                <div class="bonus-img  ">
                                                   <img src="@/assets/images/cricket-icon.svg" alt="" />
                                                </div>
                                                {{translatedLangData('cricket-fight','Cricket Fight')}}
                                             </div>
                                             <div class="bonus-btn">
                                                <div class="arrow-btn">
                                                   <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                </div>
                                             </div>
                                          </router-link>
                                       </div>
                                    </div>
                                    <div class="bonus-now" data-bs-dismiss="offcanvas" v-if="checkEventManageforSingleType('sports-book')">
                                       <div class="bonus-cum-list">
                                          <router-link to="/sports-book" @click="$emit('toggleBackButton')">
                                             <div class="bonus-icon">
                                                <div class="bonus-img">
                                                   <img src="@/assets/images/BtiIcon.webp" alt="" />
                                                </div>
                                                {{translatedLangData('sportsbook','Sportsbook')}}
                                             </div>
                                             <div class="bonus-btn">
                                                <div class="arrow-btn">
                                                   <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                </div>
                                             </div>
                                          </router-link>
                                       </div>
                                    </div>
                              </div>
                           <div class="accordion accordion-flush" id="accordionFlushsec">
                              <div class="accordion-item collapse-bar" v-for="(sport, n_index) in secondary_non_custom_sports" :key="n_index">
                                 <h2 class="accordion-header sport-acc-div" :id="'flush-headingOnes' + n_index">
                                    <div class="bonus-cum-list flex-d pt-0 pb-0 sport-acc" data-bs-dismiss="offcanvas"
                                       @click="gotoSportPage(sport), $emit('toggleBackButton')">
                                    <div class="bonus-icon">
                                       <div class="bonus-img">
                                          <img :src="getSportImage(sport.id)" alt="" loading="lazy" />
                                       </div>
                                       {{ translatedLangData(sport.slug, capitalizeFirstLetter(sport.name)) }}
                                    </div>
                                    </div>
                                    <button 
                                    class="accordion-button flex-btn collapsed sport-acc-btn" 
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    :data-bs-target="'#flush-collapsethreeon' + n_index" 
                                    aria-expanded="false" 
                                    :aria-controls="'flush-collapsethreeon' + n_index" 
                                    @click="getLeagues(sport)">
                                    <div class="accordion-div">
                                       <div class="flex-box w-cusd">
                                          <div class="content-btn">{{sportsCount?.[sport.id]?.total || 0}}</div>
                                          <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                       </div>
                                    </div>
                                    </button>
                                 </h2>
                                 <div 
                                    :id="'flush-collapsethreeon' + n_index" 
                                    class="accordion-collapse collapse" 
                                    :aria-labelledby="'flush-headingOnes' + n_index" 
                                    data-bs-parent="#accordionFlushsec">
                                    <div class="accordion-body collapse-bar">
                                    <div class="accordion accordion-flush" :id="'nestedAccordion' + n_index" v-if="sport.leagueList && Object.keys(sport.leagueList)?.length > 0">
                                       <div class="accordion-item" v-for="(league, l_index,lnIndex) in sport.leagueList" :key="l_index">
                                          <h2 class="accordion-header" :id="'nestedHeading' + n_index + '-' + l_index">
                                          <button 
                                             class="accordion-button flex-btn collapsed" 
                                             type="button" 
                                             data-bs-toggle="collapse" 
                                             :data-bs-target="'#nestedCollapse' + n_index + '-' + lnIndex" 
                                             aria-expanded="false" 
                                             :aria-controls="'nestedCollapse' + n_index + '-' + lnIndex">
                                             <div class="accordion-div">
                                                <div class="bonus-cum-list flex-d">
                                                <div class="bonus-icon ellipese-word">
                                                   <div class="bonus-img">
                                                      <img src="@/assets/images/trophy-sidebar-red.webp" alt="" />
                                                   </div>
                                                   {{ l_index }}
                                                </div>
                                                </div>
                                             </div>
                                             <div class="flex-box w-cusd">
                                                <div class="content-btn">{{ league?.length }}</div>
                                                <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                             </div>
                                          </button>
                                          </h2>
                                          <div 
                                          :id="'nestedCollapse' + n_index + '-' + lnIndex" 
                                          class="accordion-collapse collapse" 
                                          :aria-labelledby="'nestedHeading' + n_index + '-' + l_index" 
                                          :data-bs-parent="'#nestedAccordion' + n_index">
                                          <div class="accordion-body">
                                             <div class="show-and-hide hidden-menu">
                                                <div class="bonus-cum-list" v-for="(match, m_index) in league" :key="m_index">
                                                <a @click="clickOnSportItem(match), $emit('toggleBackButton')" data-bs-dismiss="offcanvas">
                                                   <div class="bonus-icon ellipese-word">
                                                      <div class="bonus-img dry-cic">
                                                      <img v-if="match?.inplay" src="@/assets/images/right-caret.svg" alt="" />
                                                      <img v-else src="@/assets/images/dry-clean.webp" alt="" />
                                                      </div>
                                                      {{ match.event_name }}
                                                   </div>
                                                   <div class="bonus-btn">
                                                      <div class="arrow-btn">
                                                      <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                      </div>
                                                   </div>
                                                </a>
                                                </div>
                                             </div>
                                          </div>
                                          </div>
                                       </div>
                                    </div>
                                    <!-- Optionally, add your "No markets" message here if needed -->
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="bonus-now">
                              
                              <div class="collapse-bar" v-for="(sport,c_index) in custom_sports" :key="c_index">
                                 <div class="accordion accordion-flush" id="accordionFlushExample">
                                    <div class="accordion-item">
                                       <h2 class="accordion-header" id="flush-headingOnes">
                                          <button class="accordion-button flex-btn collapsed"  data-bs-dismiss="offcanvas" @click="$emit('toggleBackButton') , gotoCustomSport(sport)" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapsecustom' + c_index" aria-expanded="false" :aria-controls="'flush-collapsecustom' + c_index">
                                             <div class="accordion-div">
                                                <div class="bonus-cum-list flex-d" >
                                                   <div class="bonus-icon">
                                                      <div class="bonus-img">
                                                         <img v-if="sport?.sport_icon" :src="sport?.sport_icon" alt="" />
                                                         <img v-else src="@/assets/images/casino-chip.svg" alt="" />
                                                         <!-- <img v-if="sport?.slug.trim() == 'icc-world'" src="@/assets/images/ICC_Icon.svg" alt="">
                                                         <img v-if="sport?.slug.trim() == 'lok-sabha-2024'" src="@/assets/images/Lok_Sabha_Icon.svg" alt="">
                                                         <img v-if="sport?.slug.trim() == 'ipl-winner'" src="@/assets/images/Ipl_winner_Icon.svg" alt=""> -->
                                                      </div>
                                                      {{translatedLangData(sport.slug.trim(),sport.name)}}
                                                   </div>
                                                   <div class="bonus-btn">
                                                      <div class="arrow-btn">
                                                         <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </button>
                                       </h2>
                                       <div :id="'flush-collapsecustom' + c_index" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                          <div class="collapse-bar">
                                             <div class="accordion accordion-flush" id="accordionFlushExample">
                                                <div class="accordion-item" v-for="(league,l_index) in sport.leagueList" :key="l_index">
                                                   <h2 class="accordion-header" id="flush-headingOnes">
                                                      <button
                                                         class="accordion-button flex-btn collapsed"
                                                         type="button"
                                                         data-bs-toggle="collapse"
                                                         :data-bs-target="'#flush-collapsecustom'+league[0]?.matchId"
                                                         aria-expanded="false"
                                                         aria-controls="flush-collapsethree"
                                                      >
                                                         <div class="accordion-div">
                                                            <div class="bonus-cum-list flex-d">
                                                               <div class="bonus-icon ellipese-word">
                                                                  <div class="bonus-img">
                                                                     <img src="@/assets/images/trophy-sidebar-red.webp" alt="" />
                                                                  </div>
                                                               {{l_index}}
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="flex-box w-cusd">
                                                            <div class="content-btn">{{ league?.length }}</div>
                                                            <div class="arrow-img"><img src="@/assets/images/chevron-right-light.webp" alt="" /></div>
                                                         </div>
                                                      </button>
                                                   </h2>
                                                   <div :id="'flush-collapsecustom'+ league[0]?.matchId" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                      <div class="accordion-body">
                                                         <div class="show-and-hide hidden-menu">
                                                            <div class="bonus-cum-list" v-for="(match, m_index) in matchesList" :key="m_index">
                                                               <a @click="clickOnSportItem(match) , $emit('toggleBackButton')" data-bs-dismiss="offcanvas">
                                                                  <div class="bonus-icon ellipese-word">
                                                                     <div class="bonus-img dry-cic">
                                                                        <img src="@/assets/images/dry-clean.webp" alt="" />
                                                                     </div>
                                                                     {{ match.event_name }}
                                                                  </div>
                                                                  <div class="bonus-btn">
                                                                     <div class="arrow-btn">
                                                                        <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                                     </div>
                                                                  </div>
                                                               </a>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <section class="Casino">
                                 <h2 class="sports-name">{{translatedLangData('casino','Casino')}}</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/popular-games" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/bingo-sidebar.webp" alt="" />
                                             </div>
                                             {{translatedLangData('popular-games','Popular Games')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/live-casino" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/livecasinoicon.webp" alt="" />
                                             </div>
                                             {{translatedLangData('live-casino','Live Casino')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/slot-games" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/slot-game-sidebar.webp" alt="" />
                                             </div>
                                             {{translatedLangData('slot-games','Slot Games')}} & {{translatedLangData('bingo','Bingo')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                              </section>
                              <section class="Virtual-Sports" v-if="checkEventManageforSingleType('sports-book')">
                                 <h2 class="sports-name">{{translatedLangData('virtual-sports','Virtual Sports')}}</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/sports-book" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/sidebar-cricket-vsp.webp" alt="" />
                                             </div>
                                             {{translatedLangData('cricket','Cricket')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/sports-book" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/bundesliga.png" alt="" />
                                             </div>
                                             {{translatedLangData('bundesliga','Bundesliga')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/sports-book" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/basketball.png" alt="" />
                                             </div>
                                             {{translatedLangData('basketball','Basketball')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                              </section>
                              <section class="Supernowa">
                                 <h2 class="sports-name">{{translatedLangData('royal-gaming','Royal gaming')}}</h2>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                       <router-link to="/games/royal-games" @click="$emit('toggleBackButton')">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/supernowa_provider_icon.webp" alt="" />
                                             </div>
                                             {{translatedLangData('royal-gaming','Royal gaming')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </router-link>
                                    </div>
                                 </div>
                              </section>
                              <section class="Others">
                                 <div class="collapse-btn">
                                    <h2 class="sports-name">{{translatedLangData('others','Others')}}</h2>
                                    <div class="collapse-bar">
                                       <div class="accordion accordion-flush" id="accordionFlushExample">
                                          <div class="accordion-item">
                                             <h2 class="accordion-header" id="flush-headingOnes">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                                                   <div class="accordion-div">
                                                      <div class="bonus-cum-list flex-d">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/policy-client-client.webp" alt="" />
                                                            </div>
                                                            {{translatedLangData('terms-and-policy','Terms and Policy')}}
                                                         </div>
                                                         <div class="bonus-btn ab-us">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </button>
                                             </h2>
                                             <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                   <div class="show-and-hide hidden-menu">
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/privacy-policy" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('privacy-policy','Privacy Policy')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/responsible-gaming" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('responsible-gambling','Responsible Gambling')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/terms-conditions" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('terms-and-conditions','Terms and Conditions')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/about-us" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('about-us','About Us')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/faq" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('faq','FAQ')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/kyc-policy" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('kyc-policy','KYC Policy')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/aml-policy" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('aml-policy','AML Policy')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                      <div class="bonus-cum-list" data-bs-dismiss="offcanvas">
                                                         <router-link to="/self-exculusion-policy" @click="$emit('toggleBackButton')">
                                                            <div class="bonus-icon">
                                                               <div class="bonus-img">
                                                                  <img src="@/assets/images/policy-list-client.webp" alt="" />
                                                               </div>
                                                               {{translatedLangData('self-ex-policy','Self Exclusion Policy')}}
                                                            </div>
                                                            <div class="bonus-btn">
                                                               <div class="arrow-btn">
                                                                  <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                               </div>
                                                            </div>
                                                         </router-link>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="collapse-bar">
                                       <div class="accordion accordion-flush" id="accordionFlushExample">
                                          <div class="accordion-item">
                                             <h2 class="accordion-header" id="flush-headingOnes">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"  aria-expanded="false" >
                                                   <div class="accordion-div">
                                                      <a class="bonus-cum-list flex-d" @click="showLangModal()">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/language_setting_icon-client.webp" alt="" />
                                                            </div>
                                                            {{translatedLangData('lang-settings','Language Settings')}}
                                                         </div>
                                                         <div class="bonus-btn ab-us">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </a>
                                                   </div>
                                                </button>
                                             </h2>
                                             <!-- <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingOnes" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">
                                                   <div class="show-and-hide hidden-menu">
                                                      <div class="bonus-cum-list flex-d b-bottom">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/india-flag-client.webp" alt="" />
                                                            </div>
                                                            Hindi
                                                         </div>
                                                         <div class="bonus-btn">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="bonus-cum-list flex-d b-bottom">
                                                         <div class="bonus-icon">
                                                            <div class="bonus-img">
                                                               <img src="@/assets/images/language-flag-client.webp" alt="" />
                                                            </div>
                                                            English
                                                         </div>
                                                         <div class="bonus-btn">
                                                            <div class="arrow-btn">
                                                               <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> -->
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="bonus-now">
                                    <div class="bonus-cum-list">
                                       <a @click="getRules()">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/rules-book.svg" alt="" />
                                             </div>
                                             {{translatedLangData('rules','Rules')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                 </div>
                                 <div class="bonus-now">
                                    <div class="bonus-cum-list">
                                       <a @click="openModal()">
                                          <div class="bonus-icon">
                                             <div class="bonus-img">
                                                <img src="@/assets/images/download-app.webp" alt="" />
                                             </div>
                                             {{translatedLangData('download-android-app','Download Android App')}}
                                          </div>
                                          <div class="bonus-btn">
                                             <div class="arrow-btn">
                                                <img src="@/assets/images/chevron-right-light.webp" alt="" />
                                             </div>
                                          </div>
                                       </a>
                                    </div>
                                 </div>
                              </section>
                              <div class="all-img-bg">
                                 <div class="Sponsors">
                                    <div class="Sponsors"><img src="@/assets/images/sponsersYolo1.webp" alt="sponsor"></div>
                                    <!-- <div class="Principal">
                                       <div class="Principal-inner">Principal Sponsors</div>
                                       <img src="@/assets/images/knightRidersSponcerLogo.webp" />
                                    </div>
                                    <div class="v-border"></div>
                                    <div class="Principal winner">
                                       <div class="Principal-inner">Awards And Recognition</div>
                                       <img src="@/assets/images/awardWinnerLogoFooter.webp" />
                                    </div> -->
                                 </div>
                                 <div class="gc"><img src="@/assets/images/Gaming-Curacao-Logo.webp" alt="Gaming" /></div>
                                 <div class="slide-partner">
                                    <div class="Gaming-Partners-heading">{{translatedLangData('gaming-partner','Gaming Partners')}}</div>
                                    <div class="slide-icon">
                                       <img src="@/assets/images/SwipeIcon.webp" alt="" />
                                    </div>
                                    <div class="Gaming-Partners-wrapper">
                                       <div class="slide-partner-banner">
                                          <div class="slide-partner-banner-img">
                                             <div class="partner-banner"><img src="@/assets/images/ezugiLogoNew.webp" alt="ezugiLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/aGLogoNew.webp" alt="aGLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/betGamesLogoNew.webp" alt="betGamesLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/evolutionGamesNew.webp" alt="evolutionGamesNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/netentLogoNew.webp" alt="netentLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/tvbetLogoNew.webp" alt="tvbetLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/superSpadeLogoNew.webp" alt="superSpadeLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/supernowaLogoNew.webp" alt="supernowaLogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/sexygaminglogo.webp" alt="sexygaming_logo_footer" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/BTILogoNew.webp" alt="BTILogoNew" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/7mojosIcon.webp" alt="7mojosIcon" /></div>
                                             <div class="partner-banner"><img src="@/assets/images/spribe_logo_footer.webp" alt="spribe_logo_footer" /></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                                 <template v-if="siteSettings?.business_type==2">
                                    <div class="Gaming-Partners-wrapper justify-content-center">
                                       <div class="Gaming-Partners-heading">{{translatedLangData('payment-methods','Payment Methods')}}</div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/upiLogoNew.webp" alt="upiLogoNew" />
                                       </div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/gpayLogoNew.webp" alt="gpayLogoNew" />
                                       </div>
                                       <div class="payment-method">
                                          <img src="@/assets/images/paytmLogoNew.webp" alt="paytmLogoNew" />
                                       </div>
                                    </div>
                                    <div class="Gaming-Partners-wrapper justify-content-center">
                                       <a href="https://www.gamstop.co.uk/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/gamestopLogo.webp" alt="gamestopLogo" />
                                       </a>
                                       <a href="https://www.begambleaware.org/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/beAwareLogo.webp" alt="beAwareLogo" />
                                       </a>
                                       <a href="https://www.gamcare.org.uk/" class="payment-method w-auto-s">
                                          <img src="@/assets/images/gamcareLogo.webp" alt="gamcareLogo" />
                                       </a>
                                    </div>
                                 </template>

                                 <div class="plus-age">
                                    <div class="au-img">
                                       <img src="@/assets/images/18-plus-color-footer.webp" alt="" />
                                    </div>
                                    © 2021-2023
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </aside>
      <!-- <LanguageSelection /> -->
      <DownloadApkModal />
      <div class="modal fade modal-footer-sticky yol-rules-modal" ref="siteRulesModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false"
        aria-hidden="true">
        <RulesModal :siteRuleData='siteRuleData' />
    </div>
</template>
<script>

import RulesModal from "@/shared/components/modal/RulesModal.vue";
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import { mapGetters } from 'vuex';
import click_api from '../../services/click_api';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import { Modal } from 'bootstrap';
export default {
    name: 'SideMenu',
    components:{
      RulesModal,
      DownloadApkModal
    },
    props: ['menuTabActive'],
    inject:['translatedLangData'],
    emits:['close-side-nav'],
    data(){
        return{
            // custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
            // non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
            sportsData:[],
            leagues_list:{},
            matchesList:[],
            searchText:"",
            searchResult:null,
            loading:false,
            lock_settings: null,
            siteRuleData: null,
        }
    },
    computed: {
		...mapGetters({
			sportsCount: 'sportsCount',
		}),
      siteSettings() {
            return this.$store.getters.siteSettings
        },
      bonusAvailable() {
            return this.$store.getters.bonusAvailable
        },
        custom_sports(){
            return this.$store.getters.sportsData?.custom
        },
        non_custom_sports(){
            return this.$store.getters.sportsData?.non_custom
        },
        primary_non_custom_sports(){
            return this.$store.getters.sportsData?.non_custom?.filter(sport => [1,2,3,4].includes(sport.rank))
        },
        secondary_non_custom_sports(){
            return this.$store.getters.sportsData?.non_custom?.filter(sport => ![1,2,3,4].includes(sport.rank))
        },
	},
   watch: {
     menuTabActive() {
      this.$refs.searchBarRef.blur();
      this.clearSearch();
     } 
   },
   mounted(){
      this.lock_settings = this.$store?.getters?.lock_settings;
      this.scrollTopFunc();
      this.siteRulesModal();
   },
    methods:{
      siteRulesModal() {
         this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
      },
      getRules(bonus = false) {
         if(this.siteRuleData && !bonus) {
               this.siteRuleModal.show();
               return;
         }
         if(this.siteRuleData) {
               return;
         } 
         this.$store.dispatch('setGlobalLoader', true);
         let headers = {
               'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
         }
         api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
               this.$store.dispatch('setGlobalLoader', false);
               if (response) {
                  if (response.status == 200) {
                     if (response?.data?.status == 0) {
                           this.$emit('error-modal', response.data.debug[0]);
                     }
                     else {
                           let ruleResp = response.data.data;
                           this.siteRuleData = {}
                           ruleResp.forEach(item => {
                              this.siteRuleData[item.type] = item.content;
                           })
                           if(!bonus) {
                              this.siteRuleModal.show();
                           }
                           // this.$emit('rulesModal');
                           // this.closeModal();
                     }
                  }
               }
         }).catch(error => {
               this.$store.dispatch('setGlobalLoader', false);
               if (error) {
                  this.$emit('error-modal', error[0]);
               }
         });
      },
      scrollTopFunc() {
         window.scrollTo({
               top: 0,
               behavior: "smooth"
         });
      },
      gotoSportPage(sport){
         this.$router.push('/sports-betting/'+sport?.id);
         
      },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.lock_settings?.find(item => item.type == type);                
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;    
        },
      openModal() {
         let bonusModal =  null;
         if (!bonusModal) {
            bonusModal = new Modal(document.getElementById('install-apk-model'));
         }
         bonusModal.show();
      },
      debounce(callback, timer=1000) {
            const context = this;
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                callback.apply(context);
            }, timer);
        },
        clearSearch() {
            this.searchText = '';
            this.searchResult = null;
         },
      searchIcon(){
         this.searchCasinoMatches(this.searchText)
        },
        showLangModal() {
         let bonusModal =  null;
         if (!bonusModal) {
            bonusModal = new Modal(document.getElementById('language_selection_pop_up'));
         }
         bonusModal.show();
        },  
        async searchCasinoMatches(search) {
            try {
                this.searchResult = null;
                this.loading = true;
                let headers = {
                    'Authorization':`Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }            
                let data = {
                    "search": search
                };
                api.post(apiName.SEARCH, data,{headers}).then(response => {
                    this.loading = false;
                    if (response && response.status && response.status == 200) {
                        let resData = response.data.data ?? null;
                        this.searchResult  = [...resData?.matches];
                        if(!this.searchResult){
                            this.searchResult = null;
                        }
                        if(this.noDataMessage == this.translatedLangData('invalid-data-send','Invalid data send')){
                            this.searchResult = null;
                        }
                        if(this.noDataMessage == this.translatedLangData('no-res-found',"No result found")){
                            this.searchResult = null;
                        }
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        // this.showErrorModalFunc(error[0]);
                    }
                });
            } catch (error) {
            }
        },
        checkForLockedMatches(matchId, league_id, lockedMatchIds, lockedLeagueIds) {
            return this.checkIsLogin() && (lockedMatchIds.length || lockedLeagueIds.length) ? !lockedMatchIds.includes(matchId.toString()) && !lockedLeagueIds.includes(league_id.toString()) : true
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
         },
         gotoCustomSport(sport){
            this.$router.push('/sports/' + sport.name + '/' + sport.id)
         },
        getLeagues(sport){
            if (sport.leagueList) {
                return;
            }
            this.loading = true;
            click_api.get(apiName.MATCHES_BY_SPORT_ID + sport.id).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.sportsData=response.data.data;

                        let lockedMatchIds = [];
                        let lockedLeagueIds = [];
                        if(this.checkIsLogin() && this.lock_settings) {
                            lockedMatchIds = this.lock_settings?.find(item => item.type == 'match');
                            lockedLeagueIds = this.lock_settings?.find(item => item.type == 'league');

                            lockedMatchIds = lockedMatchIds && lockedMatchIds.type_id !== null ? lockedMatchIds.type_id.split(',') : [];
                            lockedLeagueIds = lockedLeagueIds && lockedLeagueIds.type_id !== null ? lockedLeagueIds.type_id.split(',') : []
                        }

                        for (var i = this.sportsData.length - 1; i >= 0; i--) {
                            if(!this.checkForLockedMatches(this.sportsData[i].matchId, this.sportsData[i].league_id, lockedMatchIds, lockedLeagueIds)) {
                                this.sportsData.splice(i, 1)
                            }
                        }

                        sport.leagueList = {};

                        this.sportsData?.forEach(item => {
                            const leagueName = item.league_name;
                            if (!sport.leagueList[leagueName]) {
                                sport.leagueList[leagueName]=[]
                            }
                            sport.leagueList[leagueName].push(item)
                        });
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error.data.message);
                }
            });
        },
        clickOnSportItem(match){
            this.searchResult = [];
            this.searchText = '';
            this.$router.push('/sports-event-detail/' + match.event_id )
        },
        capitalizeFirstLetter(str) {
         if (!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1);
         },
         getSportImage(sportId) {
            const imageMap = {
                4: require("@/assets/images/sports-cricket.png"),
                1: require("@/assets/images/sports-soccer.png"),
                2: require("@/assets/images/sports-tennis.png"),
                1477: require("@/assets/images/sports-rugby-league.png"),
                6: require("@/assets/images/sports-boxing.png"),
                5: require("@/assets/images/sports-rugby-union.png"),
                3: require("@/assets/images/sports-golf.png"),
                27454571: require("@/assets/images/sports-esports.png"),
                2152880: require("@/assets/images/sports-gaelic-football.png"),
                998917: require("@/assets/images/sports-volleyball.png"),
                7524: require("@/assets/images/sports-ice-hockey.png"),
                7522: require("@/assets/images/sports-basketball.png"),
                7511: require("@/assets/images/sports-baseball.png"),
                6423: require("@/assets/images/sports-american-football.png"),
                6422: require("@/assets/images/sports-snooker.png"),
                11: require("@/assets/images/sports-cycling.png"),
                8: require("@/assets/images/sports-motor-sport.png"),
                2378961: require("@/assets/images/sports-politics.png"),
                468328: require("@/assets/images/sports-handball.png"),
                61420: require("@/assets/images/sports-australian-rules.png"),
                3503: require("@/assets/images/sports-darts.png"),
                10: require("@/assets/images/sports-special-bets.png"),
                26420387: require("@/assets/images/sports-mixed-martial-arts.png"),
                4339: require("@/assets/images/sports-greyhound-racing.png"),
                7: require("@/assets/images/sports-horse-racing.png"),
            };
            return imageMap[sportId] || require("@/assets/images/sports-no.png");
        },
   }
}
</script>

<style scoped>
input[type="search"]::placeholder{
   font-style: italic;
}
.sport-acc-div{
   display: flex;
   border-bottom: solid 1px var(--primary-bg);
   justify-content: space-between;
}
.sport-acc{
   padding: 0.5em 0.5em !important;
}
</style>
const ProfileModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ './views/Profile.vue');
const AccountInfo = () => import(/* webpackChunkName: "profile" */ './views/AccountInfo.vue');
const Promotions = () => import(/* webpackChunkName: "profile" */ './views/Promotions.vue');
const Messages = () => import(/* webpackChunkName: "profile" */ './views/Messages.vue');
const Loyalty = () => import(/* webpackChunkName: "profile" */ './views/Loyalty.vue');
const ChangePassword = () => import(/* webpackChunkName: "profile" */ './views/ChangePassword.vue');

const ProfileRoutes = {
    path: '/',
    component: ProfileModule,
    children: [
        {
            path: 'profile',
            name: 'profile',
            component: Profile
        },
        {
            path: 'account-info',
            name: 'account-info',
            component: AccountInfo,
            meta: {
                title: 'Account Info',
              },
        },
        {
            path: 'promotions',
            name: 'promotions',
            component: Promotions,
            meta: {
                title: 'Promotions',
              },
        },
        {
            path: 'messages',
            name: 'messages',
            component: Messages,
            meta: {
                title: 'Messages',
              },
        },
        {
            path: 'loyalty',
            name: 'loyalty',
            component: Loyalty,
        },
        {
            path: 'change-password',
            name: 'change-password',
            component: ChangePassword,
            meta: {
                title: 'Change Password',
              },
        }
    ],
}   

export default ProfileRoutes;
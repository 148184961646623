import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style1.css";
import "./assets/css/style.css";
import "./assets/css/chat.css";
import "./assets/css/chatresponsive.css";
import "./assets/css/responsive.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import VueCookies from 'vue3-cookies';
import './registerServiceWorker'
import VueAwesomePaginate from "vue-awesome-paginate";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";

router.beforeEach((to, from, next) => {
    let backgroundImage = '';
    let darkImageRoutes=[
      'profile',
      'change-password',
      'messages',
      'promotions',
      'account-info',
      'my-bets',
      'profit-loss',
      'account-statement',
      'bonus',
    ]

    if(to.name=='login' || to.name=='sign-up' || to.name=='forget-password'){
      backgroundImage = 'url(' + require('@/assets/images/IPLAuthBgEngSmallerDevices.webp') + ')';
    }
    else if(darkImageRoutes.includes(to.name)){
      backgroundImage = backgroundImage = 'url(' + require('@/assets/images/memberLayoutBGNew.webp') + ')';
    }
    else{
      backgroundImage = backgroundImage = 'url(' + require('@/assets/images/mobileMainBg.webp') + ')';
    }
    document.body.style.backgroundImage = backgroundImage;
  
    next();
  });

  router.beforeEach((to, from, next) => {
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const isAuthenticated = window.store?.getters.isAuthenticated;
    const lastLoginAt = window.store?.getters?.stateUser?.last_login_at;

    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        } else if (isAuthenticated && !lastLoginAt && to.name !== 'change-password') {
            next({ name: 'change-password' });
        } else {
            next();
        }
    }
});

const AuthorizationModule = () => import(/* webpackChunkName: "authorization-module" */ './views/Module.vue'); 
const Login = () => import(/* webpackChunkName: "login" */ './views/Login.vue');
const SignUp = () => import(/* webpackChunkName: "sign-up" */ './views/SignUp.vue');
const ForgetPassword = () => import(/* webpackChunkName: "forget-password" */ './views/ForgetPassword.vue');

const AuthorizationRoutes =   {
    path: '/',
    component: AuthorizationModule,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'sign-up',
        name: 'sign-up',
        component: SignUp
      },
      {
        path: 'forget-password',
        name: 'forget-password',
        component: ForgetPassword
      },
    ],
  }

export default AuthorizationRoutes;
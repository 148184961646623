const RaceModule = () => import(/* webpackChunkName: "race-module" */ './views/Module.vue');
const RacingCategory = () => import(/* webpackChunkName: "racing-category" */ './views/RacingCategory.vue');
const RacingListDetails = () => import(/* webpackChunkName: "racing-list-details" */ './views/RacingListDetails.vue');
const RaceRoutes = {
    path: '/',
    component: RaceModule,
    children: [
        {
            path: 'racing-category/:type',
            name: 'racing-category',
            component: RacingCategory
        },
        {
            path: 'racing-list-details/:type',
            name: 'racing-list-details',
            component: RacingListDetails
        }
    ],
}   

export default RaceRoutes;
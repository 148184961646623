<template>
    <div class="install-apk-model">
        <div class="modal fade show" id="install-apk-model" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="thm-heading">
                            <h3>{{translatedLangData('how-to-install-apk','How to Install APK')}}</h3>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img
                                src="@/assets/images/cross.svg" alt="" @click="closeModal()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="install-app-content">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                        data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                        aria-selected="true">{{translatedLangData('android','Android')}}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                        data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                        aria-selected="false">{{translatedLangData('ios','iOS')}}</button>
                                </li>

                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="how-install-content">
                                        <img src="@/assets/images/Android.jpg" alt="">
                                    </div>

                                </div>
                                <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="how-install-content">
                                        <img src="@/assets/images/iOS.jpg" alt="">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn close-btn" data-bs-dismiss="modal">{{translatedLangData('close','Close')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
    name: 'DownloadApkModal',
    inject:['translatedLangData'],
    data() {
        return {
            bonusModal: null
        }
    },
    mounted(){
        this.bonusModal = new Modal(document.getElementById('install-apk-model'));
    },
    methods:{
        closeModal(){
            if (this.bonusModal) {
                this.bonusModal.hide();
            }
        }
    },
    beforeUnmount() {
        if (this.bonusModal) {
        this.bonusModal.dispose();
        }
    }
}
</script>

<style></style>
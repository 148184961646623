const StatementModule = () => import(/* webpackChunkName: "profile-module" */ './views/Module.vue');
const MyBets = () => import(/* webpackChunkName: "profile" */ './views/MyBets.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profile" */ './views/ProfitLoss.vue');
const AccountStatement = () => import(/* webpackChunkName: "profile" */ './views/AccountStatement.vue');
const MatchMarket = () => import(/* webpackChunkName: "match-market" */ './views/MatchMarket.vue');

const ProfileRoutes = {
    path: '/',
    component: StatementModule,
    children: [
        {
            path: 'my-bets',
            name: 'my-bets',
            component: MyBets,
            meta: {
                title: 'My Bets',
              },
        },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
            meta: {
                title: 'Profit Loss',
              },
        },
        {
            path: '/account-statement/:type',
            name: 'account-statement',
            component: AccountStatement,
            meta: {
                title: 'Account Statement',
              },
        },
        {
            path: 'match-market/:item/:type',
            name: 'match-market',
            component: MatchMarket,
            meta: {
                title: 'Open Bets Details'
            }
        },
    ],
}   

export default ProfileRoutes;
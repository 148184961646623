<template>
          <!--Whatsup Deposit Modal -->
    <div class="modal fade whatsup-modal-wrapper show" id="whatsup-depositModal" aria-labelledby="whatsupModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{translatedLangData('customer-support','Customer Support')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body whatsup-modal-information">
                        <div class="whatsaap-support-btn-list" v-if="whatsappNumbers && whatsappNumbers.length > 0">
                            <div class="support-call-now" v-for="(data, index) in whatsappNumbers" :key="index">
                                <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                    <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                    <span class="btn thm-btn"> {{ data.category_name }}</span>
                                </a>      
                            </div>
                        </div>
                        <div class="whatsaap-support-btn-list" v-else-if="siteSettings && (siteSettings?.social_link?.whatsappno1 || siteSettings?.social_link?.whatsappno2)">
                        <template v-for="number in [siteSettings?.social_link?.whatsappno1, siteSettings?.social_link?.whatsappno2]">
                            <div class="support-call-now">
                            <a v-if="number" 
                                :key="number" 
                                :href="'https://wa.me/' + number" 
                                target="_blank" 
                                >
                                <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                <span class="btn thm-btn call-now"> {{ number }}</span>
                                
                            </a>
                        </div>
                        </template>
                    </div>
                    <div v-else>
                        <p>{{translatedLangData('no-whatsapp-avail','No WhatsApp numbers available')}}.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--Whatsup Deposit Modal -->
</template>

<script>
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
export default {
    name: 'whatsAppModal',
    props:['whatsappNumbers'],
    inject:['translatedLangData'],
    data() {
        return {
            bonusModal: null
        }
    },
    computed: { 
        ...mapGetters({
            siteSettings: 'siteSettings'
        })
    },
    mounted(){
        this.bonusModal = new Modal(document.getElementById('whatsup-depositModal'));
    },
    methods:{
        closeModal(){
            if (this.bonusModal) {
                this.bonusModal.hide();
            }
        }
    },
    beforeUnmount() {
        if (this.bonusModal) {
        this.bonusModal.dispose();
        }
    }
}
</script>

<style></style>
<template>
    <div class="modal fade fantasy-cricket-match-list" id="fantasyModal" tabindex="-1" aria-labelledby="exampleModalLabel" data-bs-keyboard="false" data-bs-backdrop="static"
        aria-hidden="false">
        <div class="modal-dialog onload-modal  exit-clickbet">
            <div class="modal-content">
                <div class="modal-header modal-header-theme">
                    <div class="thm-heading">
                        <h3>{{translatedLangData('fantasy-cricket','Fantasy Cricket')}}</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                </div>
                <div class="modal-body ">
                    <div class="modal-info">
                        <!-- <img src="@/assets/images/exit-clickbet-icon.webp" alt=""> -->
                    </div>
                    <div class="thm-heading d-inline-block text-center m-auto w-100" v-if="betData?.bet_with_team && betData?.bet_with_team?.length > 0">
                        <div class="game-type-accordion-start bt-0">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <p>{{translatedLangData('bet-with-team','Bet With Team')}}</p>
                                        <span class="place-code place-date"> {{ getDateTime(betData?.event_date,'MM/ DD/ YYYY HH:mm:ss') }} </span>
                                    </button>
                                </h2>

                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="collapse-data">
                                            <div class="bet-detail-div time-sheet" v-for="(item,index) in betData?.bet_with_team" :key="index">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="balance-sheet">
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('team-name','Team Name')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{item.team_name}}
                                                            </span>
                                                        </div>
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('placing-time','Placing Time')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{ displayDate(item.bet_place_time,'DD/ MM/ YYYY HH:mm:ss') }}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div>
                                                            <ul class="d-flex">
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('stake','Stake')}}</span>
                                                                    <span><strong>{{item?.stake}}</strong></span>
                                                                </li>
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('pl','P&L')}}</span>
                                                                    <span><strong>{{item?.p_l}}</strong></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="thm-heading d-inline-block text-center m-auto w-100" v-if="betData?.bet_with_run && betData?.bet_with_run?.length > 0">
                        <div class="game-type-accordion-start bt-0">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <p>{{translatedLangData('bet-with-run','Bet With Run')}}</p>
                                        <span class="place-code place-date"> {{ getDateTime(betData?.event_date,'MM/ DD/ YYYY HH:mm:ss') }} </span>
                                    </button>
                                </h2>

                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="collapse-data">
                                            <div class="bet-detail-div time-sheet pb-5" v-for="(item,index) in betData?.bet_with_run" :key="index">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="balance-sheet">
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('team-name','Team Name')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{item.team_name}}
                                                            </span>
                                                        </div>
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('placing-time','Placing Time')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{ displayDate(item.bet_place_time,'DD/ MM/ YYYY HH:mm:ss') }}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div>
                                                            <ul class="d-flex">
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('stake','Stake')}}</span>
                                                                    <span><strong>{{item?.stake}}</strong></span>
                                                                </li>
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('pl','P&L')}}</span>
                                                                    <span><strong>{{item?.p_l}}</strong></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="thm-heading d-inline-block text-center m-auto w-100" v-if="betData?.bet_with_ball && betData?.bet_with_ball?.length > 0">
                        <div class="game-type-accordion-start bt-0" >
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <p>{{translatedLangData('bet-with-ball','Bet With Ball')}}</p>
                                        <span class="place-code place-date"> {{ getDateTime(betData?.event_date,'MM/ DD/ YYYY HH:mm:ss') }} </span>
                                    </button>
                                </h2>

                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="collapse-data">
                                            <div class=" bet-detail-div time-sheet pb-5" v-for="(item,index) in betData?.bet_with_ball" :key="index">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="balance-sheet">
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('team-name','Team Name')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{item.team_name}}
                                                            </span>
                                                        </div>
                                                        <div class="time-det balane-show">
                                                            <span class="odds">
                                                                {{translatedLangData('placing-time','Placing Time')}}
                                                            </span>
                                                            <span class="statment">
                                                                {{ displayDate(item.bet_place_time,'DD/ MM/ YYYY HH:mm:ss') }}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div>
                                                            <ul class="d-flex">
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('stake','Stake')}}</span>
                                                                    <span><strong>{{item?.stake}}</strong></span>
                                                                </li>
                                                                <li class="matka-li">
                                                                    <span>{{translatedLangData('pl','P&L')}}</span>
                                                                    <span><strong>{{item?.p_l}}</strong></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: "FantasyModal",
    props:['betData'],
    inject:['translatedLangData'],
    data() {
        return {
            fantasyModal: null
        }
    },
    mounted(){
    },
    methods: {
        displayDate(date,type){
            return moment(date).format(type)
        },
        getDateTime(dateTime) {
            return moment.unix(dateTime).format('DD/ MM/ YYYY HH:mm:ss')
        },
    },
};
</script>
<style scoped>
.bet-detail-div{
    background-color: #ececec !important;
    border: none !important;
    border-radius: 4px !important;
    margin: 10px;
}
</style>
<style>
.modal-backdrop {
  z-index: -1 !important;
}
</style>
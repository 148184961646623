<template>
    <div class="modal fade game-point-note-modal change-lang-modal show" data-bs-backdrop="static" data-bs-keyboard="false" id="language_selection_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>{{translatedLangData('change-language','Change your language')}}</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="ResetLang()">
                        <!-- <img src="@/assets/images/close.webp" alt=""> -->
                    </button>
                </div>
                <div class="modal-body">
                    <section class="sub-header-wrapper">

                        <div class="langauage-content">
                            <div class="search-lang-input">
                               <input type="search" :placeholder="translatedLangData('search-key','Search')" v-model="searchTerm" class="form-control">
                                <button class="btn link-btn"><img loading="lazy" src="@/assets/images/search-grey-icon.webp"
                                        alt="search"></button>
                            </div>
                            <div class="custom-language-list">
                                <ul>
                                    <li v-for="item in filteredLanguages" :key="item?.lang_code"
                                        :class="{ 'active': selectedValue?.lang_code == item?.lang_code }">
                                        <div class="form-check" @click="this.selectedValue = item">
                                            <label class="form-check-label">
                                                {{ item.lang_name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="two-btns">
                            <ul class="cmn-ul-list">
                                <li>
                                    <button class="btn thm-bor-but" data-bs-dismiss="modal" @click="ResetLang()">{{translatedLangData('close','Close')}}</button>
                                </li>
                                <li>
                                    <button class="btn thm-but" @click="selectedlanguage">{{translatedLangData('update','Update')}}</button>
                                </li>
                            </ul>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import IFrameModal from '@/modules/games/components/IFrameModal.vue';
import LanguageService from '@/shared/services/language-service.js'
import { Modal } from 'bootstrap';

export default {
    name: 'GamesPop1',
    data() {
        return {
            openIframeModal: false,
            selectedValue: '',
            searchTerm: '',
            langModal: null,
        }
    },
    inject:['translatedLangData'],
    mounted(){
        let currentLang = localStorage.getItem('selectedLanguageCode')
        if(currentLang){
            this.selectedValue = this.siteSettings.language_translate_list.find(item => item.lang_code == currentLang);
        }
        else{
            this.selectedValue = this.siteSettings?.language_translate_list.find(item => item.lang_code == this.siteSettings.language_code)
        }
        // this.ResetLang();
        this.langModal = new Modal(document.getElementById('language_selection_pop_up'));
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        languagesData() {
            return this.$store.getters.languagesData;
        },
        filteredLanguages() {
            if (this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData
        },

    },
    methods: {
        closeModal(){
            if (this.langModal) {
                this.langModal.hide();
            }
        },
        ResetLang(){
            this.searchTerm = '';
            this.closeModal();
            if (localStorage.getItem('selectedLanguageCode')) {
                this.selectedValue = this.languagesData.find(item => item.lang_code == localStorage.getItem('selectedLanguageCode'));
            } else {
                this.selectedValue = this.languagesData.find(item => item.lang_code == this.siteSettings?.language_code);
            }
            this.currentLanguage = this.selectedValue?.lang_name;
        },
        yesAgreeMethodCall() {
            this.openIframeModal = true;
            this.$refs.iframe_pop_modal.click();
        },
        closeModal() {
            this.openIframeModal = false
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        async selectedlanguage() {
            this.searchTerm = '';
            this.currentLang == this.selectedValue?.lang_code;
            localStorage.setItem('selectedLanguageCode', this.selectedValue?.lang_code);
            if (this.selectedValue && this.selectedValue.lang_code === 'en') {
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
            this.$parent.langData = "";
            document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','Language updated successfully'));
            } 
            else if (this.selectedValue) {
                const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url
                this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
                if (this.$parent.langData) {
                    localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                    document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
                    this.showSuccessModalFunc(this.translatedLangData('language-updated-successfully','Language updated successfully'));
                } else {
                    localStorage.removeItem('selectedLanguageUrl');
                    this.showErrorModalFunc(this.translatedLangData('language-data-is-not-available','Language data is not available'))
                }
            }
        },
    },
    beforeUnmount() {
        if (this.langModal) {
        this.langModal.dispose();
        }
    },
    components: {
        IFrameModal
    }
}
</script>

<style scoped>
.change-lang-modal .cmn-ul-list {
   margin-left: -5px;
   margin-right: -5px;
 }
 .change-lang-modal .cmn-ul-list li {
   padding-left: 5px;
   padding-right: 5px;
   display: inline-block;
 }
.two-btns {
    text-align: center;
    padding: 0px 0 15px;
}
 .search-lang-input{
   position: relative;
   padding-bottom: 20px;
 }
 .link-btn {
   padding: 0;
   background-color: transparent;
   border: 0;
 }
 .search-lang-input .link-btn {
   position: absolute;
   top: 9px;
   right: 8px;
 }
 .custom-language-list ul li{
   display: inline-block;
   width: 100%;
 }
 .custom-language-list ul li .form-check {
   display: inline-block;
   justify-content: unset;
   padding: 10px;
   position: relative;
   width: 100%;
   background-color: #f2f2f2;
   border-radius: 5px;
   margin-bottom: 15px;
   border: 1px solid #f2f2f2;
   cursor: pointer;
 }
.custom-language-list ul li.active .form-check {
    border: solid 1px #4eb77e;
}
.custom-language-list ul li.active .form-check label.form-check-label {
    color: #4eb77e;
}
 .custom-language-list ul li .form-check .form-check-label {
   font-weight: 500;
   font-size: 15px;
   line-height: normal;  
   color: #746969;
 }
 .custom-language-list ul li.active .form-check:after {
   /* background-image: url(../../src/assets/images/ck-lang-icon.webp); */
   /* background-image: url(../assets/images/ck-lang-icon.webp); */
   background-size: 100%;
   content: "";
   height: 20px;
   width: 20px;
   position: absolute;
   background-repeat: no-repeat;
   right: 10px;
 }
.change-lang-modal .cmn-ul-list li {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
}
 .search-lang-input .form-control {
   padding-right: 35px;
   padding-top: 7px;
   padding-bottom: 10px;
 }
 .change-lang-modal .thm-bor-but{
   border-color: var(--bs-secondary);
   color: var(--bs-secondary) ;
 }
 .change-lang-modal .modal-header {
   background-color: #F5F5F5;
 }
.change-lang-modal .two-btns .btn {
    font-size: 14px;
    min-width: 120px;
    margin: 0;
    padding: 9px 10px;
    line-height: normal;
}
.custom-language-list {
    max-height: 240px;
    overflow-y: auto;
}
 .change-lang-modal .modal-content {
   border: 0 !important;
 }
 .gp-content .modal-header {
   border-radius: 30px 30px 0 0;
   width: 100%;
 }
.langauage-content {
    padding: 10px;
}
 .change-lang-modal .modal-header h3 {
    font-size: 16px;
}
img.gp-closeButton {
    max-width: 15px;
}
.accordion-header a.accordion-button {
    font-weight: 400;
}
.lang-icon img {
    max-width: 16px;
}

</style>

<template>
  <div>
    <section class="login-form p-bottom" loading="lazy">
         <div class="container">
            <div class="featured-box-login">
                <div class="top-heading flex-box reg">
                <!-- <img src="@/assets/images/lakr.webp" alt="" class="aw-img"> -->
                <div class="bg-log"> <h1>{{translatedLangData('signup','Signup')}}</h1></div>
                <!-- <img src="@/assets/images/etaward.webp" alt="" class="aw-b-img"> -->
                </div>
                <form class="form-control-head">
                    <h3 class="whats-with"><span class="whats-with-now">{{translatedLangData('create-account-with-mobile-number','create account with Mobile Number')}}</span></h3>
                    <div class="get-mob">
                        <label>{{translatedLangData('mobile-number','Mobile Number')}}*</label>
                        <div class="flex-box-input w-100">
                            <div class="flex-box-input-lef w-20">
                                <select v-model="form.code" class="form-select" id="autoSizingSelect" :disabled="timerRunning">
                                    <option :value="allowedCountry.phonecode"
                                       v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                       :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                </select>
                                </div>
                            <div class="flex-box-input-center w-60">
                                <input type="tel" :disabled="timerRunning" v-model="form.mobileNo" maxlength="10" minlength="10" @input="validatePhone($event)" class="form-control add" id="autoSizingInput" :placeholder="translatedLangData('enter-mobile-number','Enter Mobile Number')" />
                                </div>
                        
                            <div class="flex-box-input-rig w-20" v-if="loadingOtp || timerRunning || form.mobileNo.length != 10">
                                <button class="btn cmn-bdr-btn otp-btn" @click.prevent>
                                    {{translatedLangData('otp','OTP')}}
                                    <img src="@/assets/images/arrow-right-circle.webp" alt="">
                                </button>
                            </div>
                            <div class="flex-box-input-rig w-20 otp-btn-active" v-else >
                                <button class="btn cmn-bdr-btn otp-btn active" @click.prevent="sendOtpCall()">
                                    {{ resendOtp ? translatedLangData('resend-otp','RESEND OTP') : translatedLangData('otp','OTP')}}
                                    <img src="@/assets/images/arrow-right-circle.webp" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="warning" v-if="showErrorMobile && form.mobileNo == ''">
                       {{translatedLangData('please-enter-mobile-number','Please Enter Mobile Number')}}
                    </div>
            
                    <span id="resend_time" class="otp-countdown-text text-danger" v-if="timerRunning">
                        {{translatedLangData('resend-in','Resend In')}} : &nbsp;<span id="timer">{{ formatTime(minutes) + ':' + formatTime(seconds) }}</span>
                    </span>
                    <label class="otp-label" for="otp" v-if="isOtpApiCalled">{{translatedLangData('otp','OTP')}} *</label>
                    <div class="get-mob for mt-0" v-if="isOtpApiCalled">
                        <input type="text" maxlength="6" minlength="6" v-model="form.otp" @input="validateOTP" class="form-control" id="otp" :placeholder="translatedLangData('enter-otp','Enter OTP')" />
                    </div>

                    <div class="warning" v-if="showValidationErrorOTP && getOtp().length < 6">
                        {{translatedLangData('please-enter-otp','Please Enter OTP')}}
                    </div>

                    <div class="get-mob for">
                        <label>{{translatedLangData('user-id','User ID')}}*</label>
                        <input type="text" minlength="6" maxlength="25" v-model="form.userName" @input="validateUserID($event)" class="form-control" id="inputPassword2" :placeholder="translatedLangData('enter-user-id','Enter User ID')" />
                    </div>
                    <div class="warning" v-if="showValidationError && form.userName == ''">
                        {{translatedLangData('please-enter-user-id','Please Enter User ID')}}
                    </div>
                    <div class="warning text-left" v-else-if="showUserIDMsg">
                        {{translatedLangData('userid-must-contain-alphanumeric-and-between-6-to-25-characters','User ID Must Contain Alphanumeric And Between 6 to 25 Characters')}}
                    </div>

                    <div class="get-mob for">
                        <label>{{translatedLangData('password','Password')}}*</label>
                        <input v-if="showPassword" v-model="form.password" type="text" @input="validatePassword" minlength="8" maxlength="20" class="form-control" id="inputPassword2" :placeholder="translatedLangData('enter-pass','Enter Password')" />
                        <input v-else type="password" v-model="form.password" @input="validatePassword" class="form-control" minlength="8" maxlength="20" id="inputPassword2" :placeholder="translatedLangData('enter-pass','Enter Password')" />
                        <div class="rug-img-input">
                            <button class="paasword-eye show-pasword" @click.prevent="showPassword =!showPassword">
                                <img v-if="showPassword" src="@/assets/images/show-pasword.webp" alt="show-pass" />
                                <img v-else src="@/assets/images/hide-pasword.webp" alt="show-pass" />
                            </button>
                        </div>
                    </div>
                    <div class="warning text-left" v-if="showValidationError && form.password == ''">
                        {{translatedLangData('please-enter-password','Please Enter Password')}}
                    </div>
                    <div v-else>
                        <div class="warning text-left" v-if="passwordValidation1">
                            {{translatedLangData('must-between-8-20-characters-and-should-be-alph','Must Be Between 8-20 Characters And Should Be Alphanumeric')}}
                        </div>
                        <div class="warning text-left" v-if="passwordValidation2">
                            {{translatedLangData('password-capital-case','Must Contain At Least 1 In Capital Case')}}
                        </div>
                    </div>


                    <div class="get-mob for">
                        <label>{{translatedLangData('confirm-password','Confirm Password')}}*</label>
                        <input v-if="showConfirmPassword" type="text" minlength="8" maxlength="20" @input="validateConfirmPassword()" @keyup="checkPasswordMatch($event)" v-model="form.confirmPassword" class="form-control" id="confPassword2" :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password')" />
                        <input v-else type="password" minlength="8" maxlength="20" @input="validateConfirmPassword()" @keyup="checkPasswordMatch($event)" v-model="form.confirmPassword" class="form-control" id="confPassword2" :placeholder="translatedLangData('enter-conf-password','Enter Confirm Password')" />
                           
                        <div class="rug-img-input">
                            <button class="paasword-eye show-pasword" @click.prevent="showConfirmPassword = !showConfirmPassword">
                                <img v-if="showConfirmPassword" src="@/assets/images/show-pasword.webp" alt="show-pass" />
                                <img v-else src="@/assets/images/hide-pasword.webp" alt="show-pass" />
                            </button>
                        </div>
                    </div>

                    <div class="warning" v-if="showValidationError && form.confirmPassword == ''">{{translatedLangData('please-enter-confirm-password','Please Enter Confirm password')}}.</div>
                    <div class="warning" v-if="confirmPasswordValidation">{{translatedLangData('password-mismatch','Password Mismatch')}}.</div>
                    
                    <div class="get-mob for">
                        <label>{{translatedLangData('refer-code','Refer Code')}}</label>
                        <input type="text" @input="validateReferCode()" :disabled="hasReferCode" v-model="form.referCode" class="form-control" id="referCode" :placeholder="translatedLangData('enter-refer-code','Enter Refer Code')" />
                    </div>
                    <div class="warning" v-if="showReferCodeError">{{translatedLangData('refer-valid-msg','The Refer code must be numeric')}}.</div>

                    <div class="form-group btn-login-active">
                        <a @click="signUpCall()" class="btn btn-submit login-active">
                            {{translatedLangData('sign-up','Sign Up')}}
                        </a>
                    </div>
                    <p class="create pt-3">
                        {{translatedLangData('get-your-ready-made-id-from-whatsapp','Get your ready-made ID from whatsapp')}}
                    </p>
                    <div class="whats-app">
                        <div class="button-whatsapp">
                            <a class="btn-whatsapp" :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank" 
                                v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                <img src="@/assets/images/whatsapp.webp" alt="" /> 
                                <span>{{translatedLangData('whatsapp-now','Whatsapp Now')}}</span>
                            </a>
                        </div>
                    </div>
                    <div class="for-pass">
                        <p class="login-with-acc u-code">{{('existing-user','Existing User')}}? &nbsp;
                            <router-link to="/login">{{translatedLangData('click-here','CLICK HERE')}}</router-link>
                        </p>
                    </div>
                    <div class="yolo-footer-img">
                        <a href="javascript:void(0);">
                            <img src="@/assets/images/sponsersYolo.webp" alt="" class="aw-b-img">
                        </a>
                    </div>
                </form>
                
             </div>
         </div>
      </section>
      <!-- <section class="slide-bouns" loading="lazy">
         <div class="container">
            <div class="slide-banner-item">
               <div class="slide-bouns-banner-item">
                    <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/cbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/withdrwals1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/loyaltyc1.webp" alt="login" /></div>
                     <div class="slide-bonus-banner"> <img src="@/assets/images/wbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/tsuport1.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/cbonus1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">  <img src="@/assets/images/withdrwals1aug.webp" alt="login" /></div>
                     <div class="slide-bonus-banner">   <img src="@/assets/images/loyaltyc1.webp" alt="login" /></div>
             </div>
               <div class="swip-icon">
                  <img src="@/assets/images/SwipeIcon.webp" alt="" />
               </div>
            </div>
         </div>
      </section> -->
      <section class="bonus-wrapper" v-if="siteSettings && siteSettings?.business_type == 2 && bonusData && bonusData?.length> 0">
            <div class="container">
                <div class="banner">
                <div class="banner-item" :class="index % 2 == 0 ? 'w-fit' : ''" v-for="(item,index) in bonusData" :key="index">
                    <div v-if="item?.bonusType == 'register'">
                        <div class="bonus-heading">
                            <!-- <sup class="bonus-sub-heading ms-3 top-0">Upto</sup> -->
                            <div class="d-flex">
                                <h2>{{item?.modeAmount}}</h2>
                                <sup class="bonus-sub-heading top-0"></sup>
                            </div>
                        </div>
                        <div class="bonus-con">
                            <h4 class="bonus-con-bg">{{translatedLangData('register','Register')}}</h4>
                            <span>{{ translatedLangData('bonus','Bonus')}}</span>
                        </div>
                    </div>
                    <div v-if="item?.bonusType == 'welcome'">
                        <div class="bonus-heading ms-2">
                            <h2>{{item?.welcomeBalance}}</h2>
                            </div>
                            <div class="bonus-con five-con">
                            <h4 class="bonus-con-bg w-auto ps-2 pe-2">{{translatedLangData('welcome','Welcome')}}</h4>
                            <span>{{ translatedLangData('bonus','Bonus')}}</span>
                        </div>
                    </div>
                    <div v-if="item?.bonusType == 'first refer&earn'">
                        <div class="bonus-heading">
                            <!-- <sup class="bonus-sub-heading ms-3 top-0">Upto</sup> -->
                            <div class="d-flex">
                                <h2>{{item?.percentage}}% </h2>
                                <sup class="bonus-sub-heading top-0"></sup>
                            </div>
                        </div>
                        <div class="bonus-con">
                            <h4 class="bonus-con-bg">{{translatedLangData('first','First')}}</h4>
                            <span>{{translatedLangData('refer-earn','Refer & Earn')}}</span>
                        </div>
                    </div>
                    <div v-if="item?.bonusType == 'next 4 refer&earn'">
                        <div class="bonus-heading ms-2">
                            <h2>{{item?.percentage}}%</h2>
                            </div>
                            <div class="bonus-con five-con">
                            <h4 class="bonus-con-bg w-auto ps-2 pe-2">{{ translatedLangData('next-4-refer-earn','Next 4 Refer & Earn')}}</h4>
                            <span>{{ translatedLangData('bonus','Bonus')}}</span>
                        </div>
                    </div>
                    <div v-if="item?.bonusType == 'first deposit'">
                        <div class="bonus-heading">
                            <!-- <sup class="bonus-sub-heading ms-3 top-0">Upto</sup> -->
                            <div class="d-flex">
                                <h2>{{item?.percentage}}%</h2>
                            </div>
                        </div>
                        <div class="bonus-con">
                            <h4 class="bonus-con-bg">{{ translatedLangData('1st-deposit','1st Deposit')}}</h4>
                            <span> {{ translatedLangData('bonus','Bonus')}}</span>
                        </div>
                    </div>
                    <div v-if="item?.bonusType == 'every deposit'">
                        <div class="bonus-heading">
                            <!-- <sup class="bonus-sub-heading ms-3 top-0">Upto</sup> -->
                            <div class="d-flex">
                                <h2>{{item?.percentage}}% </h2>
                                <sup class="bonus-sub-heading top-0"></sup>
                            </div>
                        </div>
                        <div class="bonus-con">
                            <h4 class="bonus-con-bg">{{ translatedLangData('eveery-deposit','Every Deposit')}}</h4>
                            <span>{{ translatedLangData('bonus','Bonus')}}</span>
                        </div>
                    </div>
                </div>
                
                </div>

            </div>
        </section>
  </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';

export default {
   name:'SignUp',
   inject:['translatedLangData'],
   data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings?.country_code ? this.siteSettings?.country_code : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText:''
            },
            showValidationError: false,
            showValidationErrorOTP: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            showPassword:false,
            showConfirmPassword:false,
            showUserIDMsg:false,
            passwordValidation1:false,
            passwordValidation2:false,
            confirmPasswordValidation:false,
            showReferCodeError:false,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        hasReferCode() {
            return (window.location.href).includes('refer_code');
        },
        bonusData(){
            return this.$store.getters.bonusData
        }
    },
    created(){
        if(this.siteSettings && this.siteSettings?.business_type == 1){
            this.$router.push('/login')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: ""
            },
            this.showValidationErrorOTP = false,
            this.showValidationError = false,
            this.showErrorMobile = false,
            this.passwordValidation = false,
            this.isOtpApiCalled = false,
            this.resendOtp = false,
            this.resendOtpTimer = 0,
            this.timerRunning = false,
            this.minutes = 0,
            this.seconds = 0
        },
        validatePhone(event){
            this.form.mobileNo = event.target.value.replace(/\D/g, '');
        },
        validateReferCode() {
            const alphanumericRegex = /^[a-zA-Z0-9]+$/;
            const minLength = 6;
            this.form.referCode = this.form.referCode.replace(/[^0-9]/g, '');
                if (!this.form.referCode.match(alphanumericRegex)) {
                    this.showReferCodeError = true;
                } else {
                    const onlyNumbers = /^\d+$/.test(this.form.referCode);
                    if ((!onlyNumbers)) {
                        this.showReferCodeError = true;
                    } else {
                        this.showReferCodeError = false;
                    }
                }
        },
        checkEmptyField(){
            if(this.form.userName == "" || this.form.password == "" || this.form.confirmPassword == "") {
                this.showValidationError = true;
                return false
            }
            else{
                return true;
            }
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
        validatePassword() {
            this.checkEmptyField();
            const password = this.form.password;
            const minLength = 8;
            const maxLength = 20;
            this.form.password = password.replace(/[^a-zA-Z0-9]/g, '');
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(this.form.password);
            const containsSpaces = /\s/.test(password); // Check if password contains spaces
            (password.length < minLength || !containsAlphabetsAndNumbers) ? this.passwordValidation1 = true : this.passwordValidation1 = false;
            this.passwordValidation2 = !(/[A-Z]/ .test(this.form.password));
            if (containsSpaces) {
                this.form.password = password.replace(/\s/g, '');
            }
            if(password !== this.form.confirmPassword){
                this.confirmPasswordValidation = true;
            }else{
             this.confirmPasswordValidation = false;   
            }
        },
        validateOTP() {
            this.form.otp = this.form.otp.replace(/[^0-9]/g, '');
        },
        checkPasswordMatch(event) {
            let confirmPasswordVal = this.form.confirmPassword;
            if(confirmPasswordVal.length > 0) {
                confirmPasswordVal = event.target.value.replace(/\s/g, ''); // Remove any spaces from the input
                confirmPasswordVal != this.form.password ? this.confirmPasswordValidation = true : this.confirmPasswordValidation = false;
            } else {
                this.confirmPasswordValidation = false;
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo,
                cncode: this.form.code,
                type:'register'
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },
        signUpCall() {
            if (!this.loadingSignUp) {
                let o = this.getOtp();
                this.passwordValidation = false;
                if (this.form.mobileNo == "" ) {
                    this.showErrorMobile = true;
                }
                else if (o.length != 6) {
                    this.showValidationErrorOTP = true;
                    
                }else if(!this.checkEmptyField()){}
                else if (this.form.password != this.form.confirmPassword ) {
                    this.showValidationError = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "cncode" : this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$router.push('/login')
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
         showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        validateUserID(event){
            this.checkEmptyField();
            this.form.userName = event.target.value.replace(/[^a-zA-Z0-9]/g,'');
            const minLength = 6;
            const maxLength = 25;
            const containsAlphabetsAndNumbers = /^(?=.*[a-zA-Z])(?=.*\d).*$/.test(this.form.userName);
            this.form.userName.length < minLength || !containsAlphabetsAndNumbers ? this.showUserIDMsg = true : this.showUserIDMsg = false;
            if (this.form.userName.length > maxLength) {
                this.form.userName = this.form.userName.slice(0, maxLength);
            }
            const containsSpaces = /\s/.test(this.form.userName); 
            if (containsSpaces) {
                this.form.userName = this.form.userName.replace(/\s/g, '');
            }
        },
        validateConfirmPassword(){
            this.form.confirmPassword = this.form.confirmPassword.replace(/[^a-zA-Z0-9]/g, '');
        },

    },
}
</script>

<style>
.otp-label{
    text-align: left;
    display: flex;
    justify-content: flex-start;
    color: white;
    font-size: small;
    margin: 5px 0;
}

</style>
const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames: null,
    globalLoader: false,
    depositAmount: 0,
    captchaData: null,
    sportsCount: null,
    selectedGameProvider: null,
    languagesData: null,
    sportsData: null,
    bonusData: null,
    siteVersion:null,
    underMaintenance:null,
    showTV:null,
};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    favouriteGames: state => state?.favouriteGames,
    globalLoader: state => state?.globalLoader,
    depositAmount: state => state?.depositAmount,
    captchaData: state => state?.captchaData,
    sportsCount: state => state?.sportsCount,
    selectedGameProvider: state => state?.selectedGameProvider,
    sportsData: state => state?.sportsData,
    bonusData: state => state?.bonusData,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    showTV: state => state?.showTV,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    SetSportsCount({ commit }, sportsCount) {
        commit('SetSportsCount', sportsCount);
    },
    setGameProvider({ commit }, gameProvider) {
        commit('setGameProvider', gameProvider);
    },
    setSportsData({ commit }, sportsData) {
        commit('setSportsData', sportsData);
    },
    setBonusData({ commit }, bonusData) {
        commit('setBonusData', bonusData);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    setShowTV({ commit }, showTV) {
        commit('setShowTV', showTV);
    },

};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount) {
        state.depositAmount = depositAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    SetSportsCount(state, sportsCount) {
        state.sportsCount = sportsCount;
    },
    setGameProvider(state, gameProvider) {
        state.selectedGameProvider = gameProvider;
    },
    setSportsData(state, sportsData) {
        state.sportsData = sportsData;
    },
    setBonusData(state, bonusData) {
        state.bonusData = bonusData;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setShowTV(state, showTV) {
        state.showTV = showTV;
    },

};
export default {
    state,
    getters,
    actions,
    mutations
};